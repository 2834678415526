.Topbar{
    position:fixed;
    width:100%;
    left:0px;
    max-width: 900px;
    height:67px;
    top: 0px;
    right: 0px;
    padding-top:0px;
    z-index: 500;
    text-align: center;
    overflow-y: hidden;
    background: #F8F8F8;    
    opacity: 0.95;  
}

.Tobbar-mobile{
  bottom: 0px;
  top: auto;
  height:55px;
  padding-top:0px;
  padding-bottom:0px;
  border-top: 1px solid black;
  border-bottom: 0px;
}

.slideRight, .slideLeft {
    z-index:900;
}
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);

.TopbarLogoLink {
  position:relative;
}

.TopbarLogoLink a, .TopbarLogoLink a:hover, .TopbarLogoLink a:visited {
  color: black;
  font-family: "Permanent Marker";
}

.TopbarButton .ProfileNFTIcon{
  position:relative;
  top:0px;
  left:0px;
  color:black;
}
.Topbar .ProfileNFTIcon:hover, .Topbar .ProfileNFTIcon:hover a{
  color: white;  
  cursor: pointer;  
  }

.toplogo {
    height:45px;
    width:45px;
    position: relative;
    border-radius: 10px;
    color:white;
    margin-left: 5px;
    margin-right: 2px;
    margin-top:5px;
    overflow:hidden;
  }
  
.toplogo .logo-top {
  position: relative;
  z-index: 99;
}
  
.Topbar-Container{
    position:relative;
    display:inline-flex;
    padding-top: 5px;
    padding-bottom: 5px;
    margin: auto;
}

.Tobbar-Container-mobile{
  padding-bottom: 4px;
  padding-top: 0px;
  margin-bottom: 0px;
  margin-top: 0px;

}

.Topbar-Poster {
    position:relative;
    text-align: left;
    top:13px;
    left:6px;
} 

.Topbar-Poster .middle_circle {
  color:black;
}

.Topbar-Poster .middle_circle:hover {
  color:rgba(100, 100, 100, 0.75);
}

.TopbarButton{
    position:relative;
    font-weight:normal;
    display:inline-block;
    background-color: #E6E6E6;
    border:0px;
    color: black;  
    text-transform: inherit;
    border-radius: 10px;
    width: 45px;
    height:45px;
    top:5px;
    margin-left: 2px;
    margin-right: 2px;
  }

.TopbarText {
    left:50px;
    top:0px;
    padding-top:2px;
    position:absolute;
}

.TopbarButton {
    color: black;  
    text-decoration: none;
}

.TopbarButton:hover {
    /* background-color: #307FE4; */
    color: white;  
    cursor: pointer;
    background: rgba(175, 175, 175, 0.75)  
}

.TopbarButton:hover .NotifyButton{
  /* background-color: #307FE4; */
  color: white;  
}

.TopbarIcon {
    position:absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -40%);
  }

.ProfileTopBar{
  position: absolute;
  top:0px;
  left:0px;
  padding:0px;
  margin:0px;
  opacity: 0.25;
}

.Topbar .TipsProfile {
    text-align:center;
    margin:5px;
}

.Topbar-NetworkStatus {
    position:relative;
    text-align: center;
    width:100%
  }

.Topbar-LegalTerm {
    position:relative;
    text-align: center;
    width:100%;
    padding-top:10px;
}

.Topbar-Footer {
    text-align: center;
    width:100%;
    padding-top:10px;
}
  