.MainWindow{
    position:absolute;
    top:67px;
    right: 0px;
    left: 300px;
    min-width: 400px;
    max-width: 600px;
    min-height: 1000px;
    background: white;
    border: 1px solid #E6E6E6;
    border-radius: 5px;
}

.MainWindow-mobile{
    position:absolute;
    top:35px;
    right: 0px;
    left: 0px;
    min-width: unset;
    max-width: unset;
    padding-bottom: 35px;
}