.WholeWallet {
    position:relative;
    margin-top: 20px;
    margin-left:0px;
    margin-right:0px;
}

.WalletPanel { 
    position:relative;
    height:100px;
    width:100%;
}

.WalletSubPanels {
    position:relative;
    width:100%;
    padding-left:10px;
    padding-right:10px;
}

.ProfileChain {
    padding-left:0px !important;
    padding-right:0px !important;
}

.ProfileCard {
    margin-left:10px;
    margin-right:10px;
}