.postTreeBranch {
  position:absolute;
  width:1px;
  background-color: lightgray;
}

.postTreeNode {
  position:absolute;
  border-radius: 100%;
  background-color: lightgray;
}

.BlockTimeTree {
  position:absolute;
  font-size:13px;
  color:darkgray;
  text-align: center;
  transform: translate(-50%, 0)
}

.enteringNode {
    position:absolute;
    height:10px;
    width:10px;
    border-radius: 100%;
    background-color: lightgray;
  }

.leavingNode {
    position:absolute;
    height:16px;
    width:16px;
    border-radius: 100%;
    background-color: lightgray;
}

.enteringBranch {
    position:absolute;
    width:1px;
    background-color: lightgray;
    top:0px;
  }

.leavingBranch {
    position:absolute;
    width:1px;
    background-color: lightgray;
    bottom:0px;
}