.bubbleNbReplies {
    display: flex;
    color: black;
    text-align: right;
}

.repliesNb {
    margin-top:5px;
}

.bubbleNbReplies a, .bubbleNbReplies a:visited{
    color:rgba(100, 100, 100, 0.75);
}

.bubbleNbReplies a:hover{
    color:black;
}

.ExpandPostButton{
    color: rgba(100, 100, 100, 0.75);
    cursor: pointer;  
  }
  
.ExpandPostButton:hover,  .ExpandPostButton:hover a{
    color: black;  
}