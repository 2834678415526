
.bubbleEdit {
    cursor: pointer;  
    color:rgba(100, 100, 100, 0.75);
    padding:4px;
  }
  
.bubbleEdit:hover {
  color:black
}

.bubbleNoEdit {
  color:rgba(100, 100, 100, 0.75);
  padding:4px;
}
  
  
  