.ProfileNFT {
  color:rgba(100, 100, 100, 0.75);
  position: absolute;
  margin-top:10px;
}

.ProfileNFT:hover {
  color: black;  
  cursor: pointer;  
}

.NFTContainer {
  height: 400px;
  overflow: auto;
}

.NFTContainer .PictureAligned:hover{
  cursor: pointer;  
}

.NFTContainer .PictureAligned:hover img{
  opacity: 0.5;  
}