.placeholderBubble {
    position:relative;
    margin-top:0px;
    margin-left:50px;
    margin-bottom:2px;
    min-height:20px;
  }

  .post_text_style {
    font-size:larger;
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  .LinkPreview {
    margin-bottom:5px;
  }