.number_tips_out{
    position:relative;
    display: inline-block;
}

.arrowRight {
    position:absolute;
    display: inline-block;
    margin-left:-16px;
}

.diamond_out{
    display: inline-block;
    margin-right:10px;
    color:rgba(100, 100, 100, 0.75);
}