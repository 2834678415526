
.bubbleShare {
  cursor: pointer;  
  color:rgba(100, 100, 100, 0.75);
  padding:4px;
}

.bubbleShare:hover {
  color:black
}
  
.bubbleNoShare {
  color:rgba(100, 100, 100, 0.75);
  padding:4px;
}
  