.StatusPoster {
    margin:10px;
    padding-bottom:10px;
}

.StatusPoster Input {
    height:45px;
}

.StatusPoster Button {
    border-radius:5px !important;
    border:1px solid black !important;
    margin-top: 10px;
}