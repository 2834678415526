.PostHeader{
    margin-left:50px;    
}

.PostPictureAligned img{
    border-radius: 10px;
    margin-bottom:5px;
  }

.QuotedPost {
    margin:10px 0px 10px 10px;
    padding:10px 0px 10px 10px;
    border-radius: 10px;
    border: 1px solid #E6E6E6;
}

.postListActions { 
    position: relative;
  }

  .singleNFT {
    position: relative;
  }


.front_postcard {
  position: relative;
  background: white;
}

.back_postcard {
  position: relative;
  background: white;
}

.post_flip_button {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 32px;
  height: 32px;
  padding-top: 5px;
  padding-left: 5px;
  background-color: black;
  border-radius: 100%;
  font-size: 12px;
  border: 2px solid white;
  color: white;
  z-index: 1000;
  cursor: pointer;
}
