@import url(https://fonts.googleapis.com/css?family=Neucha);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
body { 
  background: #F8F8F8;    
}

.App {
  font-family: "Neucha";
  font-weight: normal;
  position: relative;
}

.App img {
  vertical-align:auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.WaitingObject{
  position:relative;
  height:200px;
  width:90%;
  margin: auto;  
  padding: 50px;
}

.StripContainer {
  position:relative;
  padding-bottom: 5px;
  padding-left:30px;
  padding-right:0px;
  margin: 0px 0px 0px 0px;
  border-radius: 0px;
  border-bottom: 1px solid #E6E6E6;
  overflow-y: hidden;
}

.EmptyWidth {
  position:relative;
}

.GalleryContainer {
  position:relative;
  height: 325px;
  background-color: rgb(37, 30, 30);
}

.AllFaces{
  padding: 0px 0px 0px 10px;
  margin: 10px 0px 0px 0px;
  border: 0px;
  display: grid;
  grid-auto-flow: row;
  z-index:100;
  scroll-behavior: smooth;
}

.SingleFace{
  position:relative;
  padding-top:0px;
  padding-right:5px;
  }

.PostOrigin {  
  width:100%;
}

.SingleFaceReply{
  width: 50px;
  height: 30px;
  top:0px;
  padding:auto;
  position:relative;
  overflow: hidden;
  }

.SingleFaceReply-mobile{
  width: 50px;
  height: 50px;
  }

.SingleFaceReply .ImgProfile{
  z-index: 0;
  position:relative;
  overflow:hidden;
}

.SingleFaceReply:hover {
  cursor: pointer;  
}

.middle_circle{
  position:absolute;
  left:0px;
  top:0px;
  color: rgba(100, 100, 100, 0.75)
}

.middle_circle:hover{
  color: rgba(0, 0, 0)
}

.middle_circle_wait{
  position:absolute;
}

.middle_circle:hover,  .middle_circle:hover a{
  cursor: pointer;  
  border-radius:100%;
  }

.SingleFace img{
  overflow:hidden;
}

.bubbleid {
  font-weight: bold;
  color: black;
  display: inline;
}

.nowrap {
  white-space: nowrap;
}

.bubbleid a, .bubbleid a:visited{
  color: black;
}

.bubbleid a:hover{
  color:rgba(79, 69, 69, 0.75);
}

.Poster {
  position: relative;
  text-align: left;
}

.AccountButton {
  margin-top: 17px;
  margin-left:75px;
  margin-right:10px;
  padding: 9px;
  display: flex;
  justify-content: center;
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: white;
  cursor: pointer
}

.SingleFaceReply .AccountButton {
  border-radius: 100%;
  position:relative;
  margin:0px;
  padding:0px;
  text-align: center;
}

.AccountButton:hover, .AccountButton:hover a {
  color: #2081E2;
}

.PostStatusInputSubmitButton {
  position: relative;
  color: black;  
  border-radius: 5px;
  background: #E6E6E6;
  border:1px solid black;
  height:45px;
  padding:0px 10px 0px 10px;
}

.PostStatusInputSubmitButton:hover{
  background: black;
  color: white;  
  cursor: pointer;
  }

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid #21bf96 !important;
}

.ant-menu-horizontal > .ant-menu-item-selected a,
.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #21bf96 !important;
}

.ant-modal-content {
  border-radius: 5px !important;
  border: 1px solid black;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.TipsProfile{
  position:relative;
  top:0px;
}
.MainWindow{
    position:absolute;
    top:67px;
    right: 0px;
    left: 300px;
    min-width: 400px;
    max-width: 600px;
    min-height: 1000px;
    background: white;
    border: 1px solid #E6E6E6;
    border-radius: 5px;
}

.MainWindow-mobile{
    position:absolute;
    top:35px;
    right: 0px;
    left: 0px;
    min-width: unset;
    max-width: unset;
    padding-bottom: 35px;
}

.ClipLoaderContainer{
    position:relative;
    }

.ImgProfile{
    border-radius:10px;
    display:inline-block;
    }
  
.ImgProfile img{
    border-radius:10px;
    }

.ImgFaded img {
    opacity: 0.1;
    }

.nft_name {
    position:absolute;
    top: 30px;
    left: 0px;
    width:100%;
    height:45px;
    color: black;
    }

.nft_name a{ 
    color: black;
    }
    
.nft_name a:hover{ 
    color:#2081E2;
    cursor: pointer;  
    }

.nft_info {
    position: relative;
    margin-left:10px;
    padding-top:10px;
    }

.NFT_container {
    position:relative;
    display: inline-block;
    }
      
.flip_button {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 32px;
    height: 32px;
    padding-top: 5.5px;
    padding-left: 5px;
    background-color: black;
    border-radius: 100%;
    font-size: 11px;
    border: 2px solid white;
    color: white;
    z-index: 1000;
    cursor: pointer;
}

.front_card {
    position: relative;
}

.back_card {
    position: relative;
    min-height: 250px;
    min-width: 350px;
}

.single_info {
    width: 100%;
    padding: 5px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
}

.single_info img {
    margin-right: 5px;
}

.transferbutton {
    position:relative;
    color: black;
    background-color: white;
    border: solid 1px black;
    border-radius: 100%;
    padding-left:8px;
    padding-top:5px;
    width:30px;
    height:30px;
  }
  
  .transferbutton:hover{
    color:#2081E2;
    cursor: pointer;  
  }
  
  .CheckedFace {
    position:relative;
    color: black;
    background-color: white;
    border: solid 1px black;
    border-radius: 100%;
    padding-left:4px;
    padding-top:4px;
    width:30px;
    height:30px;
  }
.number_tips_in{
    position:relative;
    display: inline-block;
}

.arrowLeft {
    position:absolute;
    margin-left:2px;
}

.diamond_in{
    display: inline-block;
    margin-left:10px;
    color:rgba(100, 100, 100, 0.75);
}
.number_tips_out{
    position:relative;
    display: inline-block;
}

.arrowRight {
    position:absolute;
    display: inline-block;
    margin-left:-16px;
}

.diamond_out{
    display: inline-block;
    margin-right:10px;
    color:rgba(100, 100, 100, 0.75);
}
.WalletCard {
    position:relative;
    min-height: 110px;
}

.PictureWallet {
    position:absolute;
    left:0px;
    height:100px;
    width: 100px;
    display: inline-block;
}

.DescriptionWallet {    
    left:100px;
    position:absolute;
    padding-left:10px;
    right:0px;
    display: inline-block;
    word-break: break-all; 
}

.StatusWallet {
    position:relative;
    margin-left:100px;
    margin-top: 75px;
    margin-right:0px;
    padding-top:10px;
    padding-left:10px;
    padding-right:10px;
    padding-bottom:10px;
    display: inline-block;
}

.Selected {
    background: lightgray;
}

.ListSelectors {
    margin-bottom:50px;
    position:relative
}

.ListSelectorSupporters {
    position:absolute;
    text-align: center;
    left:100px;
    width:200px;
}

.ListSelectorSupporters:hover{
    background: #2081E2;  
    cursor: pointer;  
    }

.ListSelectorSupporting {
    position:absolute;
    text-align: center;
    left:300px;
    width:200px;
}

.ListSelectorSupporting:hover{
    background: #2081E2;  
    cursor: pointer;  
    }

.ListSelectorSupportersSelected {
    position:absolute;
    left:100px;
    background: lightgray;
    text-align: center;
    width:200px;
}

.ListSelectorSupportersSelected:hover{
    background: #2081E2;  
    cursor: pointer;  
    }

.ListSelectorSupportingSelected {
    position:absolute;
    left:300px;
    background: lightgray;
    text-align: center;
    width:200px;
}

.ListSelectorSupportingSelected:hover{
    background: #2081E2;  
    cursor: pointer;  
    }
.StatusProfile {
    position:relative;
}
.WallletPopup{
    width:300px;
}
.AttachNFT {
  color:rgba(100, 100, 100, 0.75);
  position: absolute;
  margin-left: 200px;
  margin-top: 10px;
}

.AttachNFT:hover {
  color: black;  
  cursor: pointer;  
  }

.NFT_title {
  position: absolute;
}

.AttachModal Input{
  margin-right: 10px;
  position: relative;
}

.AttachModal .PostStatusInputSubmitButton{
  margin-left : 50%;
  margin-top : 10px;
}
.Poster .PostStatusInputSubmit{ 
    margin-top:5px;
    position: relative;
    left:100%;
}

.Poster .PostStatusInputSubmitButton{ 
    position: relative;
    transform: translate(-100%);
}

textarea {
    border:0px;
    width:85%;
    margin-left: 50px;
}

.Poster{
    left: 0px;
    right:30px;
    padding-bottom: 0px;
}

.PosterTools {
    position: relative;
}

.PosterPicture {
    position: relative;
  }

.PosterPicture img {
    border-radius: 10px;
}

.modalQuotedPost {
    margin:10px 0px 10px 10px;
    padding:10px 0px 10px 10px;
    border-radius: 10px;
    border: 1px solid #E6E6E6;
}

.ReplyPicture {
    margin-right:30px;
    margin-left: 24px;
}

.ReplyPicture .postTreeBranch {
    bottom:-20px !important;
}

.tmp_image_holder {
    position:relative;
}

.post_tmp_image {
    position: relative;
    opacity: 0.5;
    border-radius:10px;
    display: inline-block;
    max-width: 300px;
    max-height: 300px;
    overflow: hidden;
}

.post_final_image {
    position: relative;
    border-radius:10px;
    display: inline-block;
    max-width: 300px;
    max-height: 300px;
    overflow: hidden;
}

.overlay_loading {
    top: 0px;
    left: 0px;
    padding-top: 20px;
    padding-left: 20px;
    position: absolute;
    z-index: 1000;
}

.overlay_minting {
    top: 0px;
    left: 0px;
    padding-top: 29px;
    padding-left: 12px;
    position: absolute;
    z-index: 1001;
    color : #1c7b87;
    font-size: 20px;
}

.OptionalSubPanel {
    position: relative;
  }

.OptionalSubPanelGallery {
    height: 350px;
    overflow-y: auto;
}
  
.OptionalSubPanel .PictureAligned:hover{
    cursor: pointer;  
}

.OptionalSubPanel .PictureAligned:hover img{
    opacity: 0.5;  
}
.WalletThumb {
    display: inline-block;
    position: absolute;
    border-radius: 100%;
    overflow: hidden;
    background-color: black;
    border: 1px solid rgba(100, 100, 100, 0.75);

}

.WalletThumb .ImgProfile img {
    border-radius: 0px;
    vertical-align: top;
}


.TipList {
    position: absolute;    
    left: 250px;
    margin-top: 5px;
    font-size: 12px;
}

.TipMobile {
    left: 0px;
    top: 50px;
    position: relative;
}

.SingleTip {
    position: relative;
    margin: 0px;
    padding: 0px;
}

.CurrentToken {
    display: inline-block;
}

.TipUser {
    position:relative;
    display: inline-block;
    margin-left:4px;
}
.NFTList {
  height:500px;
  overflow-y: scroll;
}

.PictureAligned{
  position:relative;
  overflow: hidden;
  border-radius: 10px;    
  display:inline-block;
}

.SingleNFTWallet {
  display:inline-block;
  position: relative;
  min-height: 100px;
  min-width: 100px;
}

.AllProfiles .PictureAligned{
  margin: 10px;
}
.CameraNFT {
  color:rgba(100, 100, 100, 0.75);
  position: absolute;
  margin-left: 50px;
  margin-top: 10px;
}

.CameraNFT:hover {
  color: black;  
  cursor: pointer;  
  }

.CameraTaker {
  width: 300px;
  height: 300px;
  overflow: hidden;
  border-radius: 10px;
}

.CameraTaker img {
  height: 100%;
} 

.ImageCam {
  padding-left:50px;
}

.CameraSubmit .PostStatusInputSubmitButton{ 
  margin-top:10px !important;
  margin-left:250px !important;
}

.CameraSwitch {
    position:absolute;
    left:20px;
    top:20px;
    color:white;
  }
  
.CameraSwitch:hover {
  color: #2081E2;  
  cursor: pointer;  
  }

.CameraList {
  position: absolute;
  left:20px;
  top:20px;
}

.CameraNumber {
  position: relative;
  color:white;
}

.CameraNumber:hover {
  color: #2081E2;  
  cursor: pointer;  
  }

.UploadNFTIcon {
  color:rgba(100, 100, 100, 0.75);
  position: absolute;
  margin-left: 150px;
  margin-top: 10px;
}

.UploadNFTIcon:hover {
  color: black;  
  cursor: pointer;  
  }

.UploadSubmit .PostStatusInputSubmitButton{ 
  margin-top:50px;
  margin-left:250px;
}
.container {
    background-color: #f3f3f3;
    padding: 30px 0px 30px 30px;
    margin: 10px 50px 10px 10px;
    color: #b2b2b2;
    border-radius: 10px;
    border: 1px dashed #b2b2b2;
}

.upload-box {
    position: relative;
}

.container .PostStatusInputSubmitButton {
    margin-left: 50% !important;
}
.emojiIcon {
  color:rgba(100, 100, 100, 0.75);
  position: absolute;
  margin-top:12px;
  margin-left:102px;
}

.emojiIcon:hover {
  color: black;  
  cursor: pointer;  
}

.emojipicker {
  overflow-y: scroll;
}

.emoji-mart {
  width: 100% !important;
}

.emoji-mart-scroll {
  height: 200px !important;
}

.emoji-mart-preview { display: none; } 


.bubbleReply {
    cursor: pointer;  
    color:rgba(100, 100, 100, 0.75);
    padding:4px;
  }
  
.bubbleReply:hover {
  color:black
}

.bubbleNoReply {
  color:rgba(100, 100, 100, 0.75);
  padding:4px;
}
  
  
  
.bubbleNbReplies {
    display: flex;
    color: black;
    text-align: right;
}

.repliesNb {
    margin-top:5px;
}

.bubbleNbReplies a, .bubbleNbReplies a:visited{
    color:rgba(100, 100, 100, 0.75);
}

.bubbleNbReplies a:hover{
    color:black;
}

.ExpandPostButton{
    color: rgba(100, 100, 100, 0.75);
    cursor: pointer;  
  }
  
.ExpandPostButton:hover,  .ExpandPostButton:hover a{
    color: black;  
}

.bubbleShare {
  cursor: pointer;  
  color:rgba(100, 100, 100, 0.75);
  padding:4px;
}

.bubbleShare:hover {
  color:black
}
  
.bubbleNoShare {
  color:rgba(100, 100, 100, 0.75);
  padding:4px;
}
  
.bubbleNbShares {
    display: flex;
    color:rgba(100, 100, 100);
    text-align: right;
    position: absolute;
    left: 110px;
    top: 0px;
}

.shareNb {
    margin-top:5px;
}

.bubbleNbShares a, .bubbleNbShares a:visited{
    color:rgba(100, 100, 100, 0.75);
}

.bubbleNbShares a:hover{
    color:black;
}
.bubbleTip {
    color:rgba(100, 100, 100, 0.75);
    position: absolute;
    left: 60px;
    top: 0px;
  }

.tiptext {
  margin-left:5px;
  display:inline;
}

.bubbleTipHover:hover .heartBubble{
    color:black;
}

.bubbleTip .tipimage {
    border:none;
    border: 0px;  
    background-color: transparent;
    outline : none;
    display: inline;
  }

  .SingleTipHash {
    position:relative;
    height: 60px;
    width: 150px;
  }
  
  .TipHash {
    position:absolute;
    top: 3px;
  }

  .TipThumb {
    top: 25px;
    width: 30px;
    height: 30px;
    position:relative;
  }

  .TipGiver {
    position:absolute;
    left:45px;
    top: 25px;
  }


  

.placeholderBubble {
    position:relative;
    margin-top:0px;
    margin-left:50px;
    margin-bottom:2px;
    min-height:20px;
  }

  .post_text_style {
    font-size:larger;
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  .LinkPreview {
    margin-bottom:5px;
  }
.postTreeBranch {
  position:absolute;
  width:1px;
  background-color: lightgray;
}

.postTreeNode {
  position:absolute;
  border-radius: 100%;
  background-color: lightgray;
}

.BlockTimeTree {
  position:absolute;
  font-size:13px;
  color:darkgray;
  text-align: center;
  transform: translate(-50%, 0)
}

.enteringNode {
    position:absolute;
    height:10px;
    width:10px;
    border-radius: 100%;
    background-color: lightgray;
  }

.leavingNode {
    position:absolute;
    height:16px;
    width:16px;
    border-radius: 100%;
    background-color: lightgray;
}

.enteringBranch {
    position:absolute;
    width:1px;
    background-color: lightgray;
    top:0px;
  }

.leavingBranch {
    position:absolute;
    width:1px;
    background-color: lightgray;
    bottom:0px;
}
.LoginList { 
    display: grid;
    grid-auto-flow: column;
    position: relative;
}

.WalletImg {
    background-color: white;
    border-radius: 10px;
    border: 3px solid #E6E6E6;
    margin: 5px;
    padding: 10px;
    width: 125px;
    height: 125px;
    margin:auto;
}

.WalletImg:hover {
    border: 3px solid #307FE4;
    cursor: pointer;
}

.WalletText {
    width: 125px;
    margin:auto;
    font-size: 14px;
    text-align: center;
}
.BlockTime {
    display: inline;
    color: gray;
}

.bubbleEdit {
    cursor: pointer;  
    color:rgba(100, 100, 100, 0.75);
    padding:4px;
  }
  
.bubbleEdit:hover {
  color:black
}

.bubbleNoEdit {
  color:rgba(100, 100, 100, 0.75);
  padding:4px;
}
  
  
  
.bubbleEdit a, .bubbleEdit a:visited{
    color:rgba(100, 100, 100, 0.75);
}

.bubbleEdit a:hover{
    color:black;
}

.bubbleEdit {
    display: flex;
    color:rgba(100, 100, 100, 0.75);
    text-align: right;
    position: absolute;
    left: 80px;
    top: 0px;
}
.PostHeader{
    margin-left:50px;    
}

.PostPictureAligned img{
    border-radius: 10px;
    margin-bottom:5px;
  }

.QuotedPost {
    margin:10px 0px 10px 10px;
    padding:10px 0px 10px 10px;
    border-radius: 10px;
    border: 1px solid #E6E6E6;
}

.postListActions { 
    position: relative;
  }

  .singleNFT {
    position: relative;
  }


.front_postcard {
  position: relative;
  background: white;
}

.back_postcard {
  position: relative;
  background: white;
}

.post_flip_button {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 32px;
  height: 32px;
  padding-top: 5px;
  padding-left: 5px;
  background-color: black;
  border-radius: 100%;
  font-size: 12px;
  border: 2px solid white;
  color: white;
  z-index: 1000;
  cursor: pointer;
}


.WholeWallet {
    position:relative;
    margin-top: 20px;
    margin-left:0px;
    margin-right:0px;
}

.WalletPanel { 
    position:relative;
    height:100px;
    width:100%;
}

.WalletSubPanels {
    position:relative;
    width:100%;
    padding-left:10px;
    padding-right:10px;
}

.ProfileChain {
    padding-left:0px !important;
    padding-right:0px !important;
}

.ProfileCard {
    margin-left:10px;
    margin-right:10px;
}
.StatusPoster {
    margin:10px;
    padding-bottom:10px;
}

.StatusPoster Input {
    height:45px;
}

.StatusPoster Button {
    border-radius:5px !important;
    border:1px solid black !important;
    margin-top: 10px;
}

.ProfileNFT {
  color:rgba(100, 100, 100, 0.75);
  position: absolute;
  margin-top:10px;
}

.ProfileNFT:hover {
  color: black;  
  cursor: pointer;  
}

.NFTContainer {
  height: 400px;
  overflow: auto;
}

.NFTContainer .PictureAligned:hover{
  cursor: pointer;  
}

.NFTContainer .PictureAligned:hover img{
  opacity: 0.5;  
}
.ProfilePoster{
    position:relative;
    display:inline-block;
}

.ProfilePoster Input{   
    margin-left:10px;
    height:45px;
}

.ProfilePoster .ProfileNFT{
    position:relative;
    display:inline-block;
    top:10px;
}

.PreviewContract{
    margin: 5px;
    display: inline-block;
    border-radius: 10px;
    overflow: hidden;
}

.StatusProfPoster {
    position:relative;
    margin:10px;
    padding-bottom:10px;
}

.StatusProfPoster Button{
    margin-top:10px;
    border-radius:5px !important;
}

.PostStatusInputSubmitButton:hover {
    border: 1px solid black !important;
}
.Topbar{
    position:fixed;
    width:100%;
    left:0px;
    max-width: 900px;
    height:67px;
    top: 0px;
    right: 0px;
    padding-top:0px;
    z-index: 500;
    text-align: center;
    overflow-y: hidden;
    background: #F8F8F8;    
    opacity: 0.95;  
}

.Tobbar-mobile{
  bottom: 0px;
  top: auto;
  height:55px;
  padding-top:0px;
  padding-bottom:0px;
  border-top: 1px solid black;
  border-bottom: 0px;
}

.slideRight, .slideLeft {
    z-index:900;
}

.TopbarLogoLink {
  position:relative;
}

.TopbarLogoLink a, .TopbarLogoLink a:hover, .TopbarLogoLink a:visited {
  color: black;
  font-family: "Permanent Marker";
}

.TopbarButton .ProfileNFTIcon{
  position:relative;
  top:0px;
  left:0px;
  color:black;
}
.Topbar .ProfileNFTIcon:hover, .Topbar .ProfileNFTIcon:hover a{
  color: white;  
  cursor: pointer;  
  }

.toplogo {
    height:45px;
    width:45px;
    position: relative;
    border-radius: 10px;
    color:white;
    margin-left: 5px;
    margin-right: 2px;
    margin-top:5px;
    overflow:hidden;
  }
  
.toplogo .logo-top {
  position: relative;
  z-index: 99;
}
  
.Topbar-Container{
    position:relative;
    display:inline-flex;
    padding-top: 5px;
    padding-bottom: 5px;
    margin: auto;
}

.Tobbar-Container-mobile{
  padding-bottom: 4px;
  padding-top: 0px;
  margin-bottom: 0px;
  margin-top: 0px;

}

.Topbar-Poster {
    position:relative;
    text-align: left;
    top:13px;
    left:6px;
} 

.Topbar-Poster .middle_circle {
  color:black;
}

.Topbar-Poster .middle_circle:hover {
  color:rgba(100, 100, 100, 0.75);
}

.TopbarButton{
    position:relative;
    font-weight:normal;
    display:inline-block;
    background-color: #E6E6E6;
    border:0px;
    color: black;  
    text-transform: inherit;
    border-radius: 10px;
    width: 45px;
    height:45px;
    top:5px;
    margin-left: 2px;
    margin-right: 2px;
  }

.TopbarText {
    left:50px;
    top:0px;
    padding-top:2px;
    position:absolute;
}

.TopbarButton {
    color: black;  
    text-decoration: none;
}

.TopbarButton:hover {
    /* background-color: #307FE4; */
    color: white;  
    cursor: pointer;
    background: rgba(175, 175, 175, 0.75)  
}

.TopbarButton:hover .NotifyButton{
  /* background-color: #307FE4; */
  color: white;  
}

.TopbarIcon {
    position:absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -40%);
  }

.ProfileTopBar{
  position: absolute;
  top:0px;
  left:0px;
  padding:0px;
  margin:0px;
  opacity: 0.25;
}

.Topbar .TipsProfile {
    text-align:center;
    margin:5px;
}

.Topbar-NetworkStatus {
    position:relative;
    text-align: center;
    width:100%
  }

.Topbar-LegalTerm {
    position:relative;
    text-align: center;
    width:100%;
    padding-top:10px;
}

.Topbar-Footer {
    text-align: center;
    width:100%;
    padding-top:10px;
}
  
.NotificationAllTips {
    margin-top: 20px;
}

.NotificationTipLine {
    display: inline;
}

.NotificationTipper {
    display: inline;
}

.TipperPost {
    background-color: white;
    border-radius:10px;
    padding:20px;
    border-radius: 10px;
    border: 1px solid #E6E6E6;
    margin-right:20px;
}

.NotificationTipLine .WalletCard {
    position:relative;
    height:70px;
    margin:5px;
    margin-bottom:5px;
    padding-bottom:5px;
    border-bottom: 0px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

