.AttachNFT {
  color:rgba(100, 100, 100, 0.75);
  position: absolute;
  margin-left: 200px;
  margin-top: 10px;
}

.AttachNFT:hover {
  color: black;  
  cursor: pointer;  
  }

.NFT_title {
  position: absolute;
}

.AttachModal Input{
  margin-right: 10px;
  position: relative;
}

.AttachModal .PostStatusInputSubmitButton{
  margin-left : 50%;
  margin-top : 10px;
}