.LoginList { 
    display: grid;
    grid-auto-flow: column;
    position: relative;
}

.WalletImg {
    background-color: white;
    border-radius: 10px;
    border: 3px solid #E6E6E6;
    margin: 5px;
    padding: 10px;
    width: 125px;
    height: 125px;
    margin:auto;
}

.WalletImg:hover {
    border: 3px solid #307FE4;
    cursor: pointer;
}

.WalletText {
    width: 125px;
    margin:auto;
    font-size: 14px;
    text-align: center;
}