
.bubbleReply {
    cursor: pointer;  
    color:rgba(100, 100, 100, 0.75);
    padding:4px;
  }
  
.bubbleReply:hover {
  color:black
}

.bubbleNoReply {
  color:rgba(100, 100, 100, 0.75);
  padding:4px;
}
  
  
  