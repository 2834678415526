.emojiIcon {
  color:rgba(100, 100, 100, 0.75);
  position: absolute;
  margin-top:12px;
  margin-left:102px;
}

.emojiIcon:hover {
  color: black;  
  cursor: pointer;  
}

.emojipicker {
  overflow-y: scroll;
}

.emoji-mart {
  width: 100% !important;
}

.emoji-mart-scroll {
  height: 200px !important;
}

.emoji-mart-preview { display: none; } 
