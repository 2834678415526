.number_tips_in{
    position:relative;
    display: inline-block;
}

.arrowLeft {
    position:absolute;
    margin-left:2px;
}

.diamond_in{
    display: inline-block;
    margin-left:10px;
    color:rgba(100, 100, 100, 0.75);
}