.container {
    background-color: #f3f3f3;
    padding: 30px 0px 30px 30px;
    margin: 10px 50px 10px 10px;
    color: #b2b2b2;
    border-radius: 10px;
    border: 1px dashed #b2b2b2;
}

.upload-box {
    position: relative;
}

.container .PostStatusInputSubmitButton {
    margin-left: 50% !important;
}