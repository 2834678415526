.ClipLoaderContainer{
    position:relative;
    }

.ImgProfile{
    border-radius:10px;
    display:inline-block;
    }
  
.ImgProfile img{
    border-radius:10px;
    }

.ImgFaded img {
    opacity: 0.1;
    }

.nft_name {
    position:absolute;
    top: 30px;
    left: 0px;
    width:100%;
    height:45px;
    color: black;
    }

.nft_name a{ 
    color: black;
    }
    
.nft_name a:hover{ 
    color:#2081E2;
    cursor: pointer;  
    }

.nft_info {
    position: relative;
    margin-left:10px;
    padding-top:10px;
    }

.NFT_container {
    position:relative;
    display: inline-block;
    }
      
.flip_button {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 32px;
    height: 32px;
    padding-top: 5.5px;
    padding-left: 5px;
    background-color: black;
    border-radius: 100%;
    font-size: 11px;
    border: 2px solid white;
    color: white;
    z-index: 1000;
    cursor: pointer;
}

.front_card {
    position: relative;
}

.back_card {
    position: relative;
    min-height: 250px;
    min-width: 350px;
}

.single_info {
    width: 100%;
    padding: 5px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
}

.single_info img {
    margin-right: 5px;
}

.transferbutton {
    position:relative;
    color: black;
    background-color: white;
    border: solid 1px black;
    border-radius: 100%;
    padding-left:8px;
    padding-top:5px;
    width:30px;
    height:30px;
  }
  
  .transferbutton:hover{
    color:#2081E2;
    cursor: pointer;  
  }
  
  .CheckedFace {
    position:relative;
    color: black;
    background-color: white;
    border: solid 1px black;
    border-radius: 100%;
    padding-left:4px;
    padding-top:4px;
    width:30px;
    height:30px;
  }