.bubbleEdit a, .bubbleEdit a:visited{
    color:rgba(100, 100, 100, 0.75);
}

.bubbleEdit a:hover{
    color:black;
}

.bubbleEdit {
    display: flex;
    color:rgba(100, 100, 100, 0.75);
    text-align: right;
    position: absolute;
    left: 80px;
    top: 0px;
}