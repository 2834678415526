.bubbleTip {
    color:rgba(100, 100, 100, 0.75);
    position: absolute;
    left: 60px;
    top: 0px;
  }

.tiptext {
  margin-left:5px;
  display:inline;
}

.bubbleTipHover:hover .heartBubble{
    color:black;
}

.bubbleTip .tipimage {
    border:none;
    border: 0px;  
    background-color: transparent;
    outline : none;
    display: inline;
  }

  .SingleTipHash {
    position:relative;
    height: 60px;
    width: 150px;
  }
  
  .TipHash {
    position:absolute;
    top: 3px;
  }

  .TipThumb {
    top: 25px;
    width: 30px;
    height: 30px;
    position:relative;
  }

  .TipGiver {
    position:absolute;
    left:45px;
    top: 25px;
  }


  
