.bubbleNbShares {
    display: flex;
    color:rgba(100, 100, 100);
    text-align: right;
    position: absolute;
    left: 110px;
    top: 0px;
}

.shareNb {
    margin-top:5px;
}

.bubbleNbShares a, .bubbleNbShares a:visited{
    color:rgba(100, 100, 100, 0.75);
}

.bubbleNbShares a:hover{
    color:black;
}