
.TipList {
    position: absolute;    
    left: 250px;
    margin-top: 5px;
    font-size: 12px;
}

.TipMobile {
    left: 0px;
    top: 50px;
    position: relative;
}

.SingleTip {
    position: relative;
    margin: 0px;
    padding: 0px;
}

.CurrentToken {
    display: inline-block;
}

.TipUser {
    position:relative;
    display: inline-block;
    margin-left:4px;
}