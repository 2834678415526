@import url(https://fonts.googleapis.com/css?family=Neucha);

body { 
  background: #F8F8F8;    
}

.App {
  font-family: "Neucha";
  font-weight: normal;
  position: relative;
}

.App img {
  vertical-align:auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.WaitingObject{
  position:relative;
  height:200px;
  width:90%;
  margin: auto;  
  padding: 50px;
}

.StripContainer {
  position:relative;
  padding-bottom: 5px;
  padding-left:30px;
  padding-right:0px;
  margin: 0px 0px 0px 0px;
  border-radius: 0px;
  border-bottom: 1px solid #E6E6E6;
  overflow-y: hidden;
}

.EmptyWidth {
  position:relative;
}

.GalleryContainer {
  position:relative;
  height: 325px;
  background-color: rgb(37, 30, 30);
}

.AllFaces{
  padding: 0px 0px 0px 10px;
  margin: 10px 0px 0px 0px;
  border: 0px;
  display: grid;
  grid-auto-flow: row;
  z-index:100;
  scroll-behavior: smooth;
}

.SingleFace{
  position:relative;
  padding-top:0px;
  padding-right:5px;
  }

.PostOrigin {  
  width:100%;
}

.SingleFaceReply{
  width: 50px;
  height: 30px;
  top:0px;
  padding:auto;
  position:relative;
  overflow: hidden;
  }

.SingleFaceReply-mobile{
  width: 50px;
  height: 50px;
  }

.SingleFaceReply .ImgProfile{
  z-index: 0;
  position:relative;
  overflow:hidden;
}

.SingleFaceReply:hover {
  cursor: pointer;  
}

.middle_circle{
  position:absolute;
  left:0px;
  top:0px;
  color: rgba(100, 100, 100, 0.75)
}

.middle_circle:hover{
  color: rgba(0, 0, 0)
}

.middle_circle_wait{
  position:absolute;
}

.middle_circle:hover,  .middle_circle:hover a{
  cursor: pointer;  
  border-radius:100%;
  }

.SingleFace img{
  overflow:hidden;
}

.bubbleid {
  font-weight: bold;
  color: black;
  display: inline;
}

.nowrap {
  white-space: nowrap;
}

.bubbleid a, .bubbleid a:visited{
  color: black;
}

.bubbleid a:hover{
  color:rgba(79, 69, 69, 0.75);
}

.Poster {
  position: relative;
  text-align: left;
}

.AccountButton {
  margin-top: 17px;
  margin-left:75px;
  margin-right:10px;
  padding: 9px;
  display: flex;
  justify-content: center;
  text-align: center;
  width: fit-content;
  color: white;
  cursor: pointer
}

.SingleFaceReply .AccountButton {
  border-radius: 100%;
  position:relative;
  margin:0px;
  padding:0px;
  text-align: center;
}

.AccountButton:hover, .AccountButton:hover a {
  color: #2081E2;
}

.PostStatusInputSubmitButton {
  position: relative;
  color: black;  
  border-radius: 5px;
  background: #E6E6E6;
  border:1px solid black;
  height:45px;
  padding:0px 10px 0px 10px;
}

.PostStatusInputSubmitButton:hover{
  background: black;
  color: white;  
  cursor: pointer;
  }

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid #21bf96 !important;
}

.ant-menu-horizontal > .ant-menu-item-selected a,
.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #21bf96 !important;
}

.ant-modal-content {
  border-radius: 5px !important;
  border: 1px solid black;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.TipsProfile{
  position:relative;
  top:0px;
}