.WalletThumb {
    display: inline-block;
    position: absolute;
    border-radius: 100%;
    overflow: hidden;
    background-color: black;
    border: 1px solid rgba(100, 100, 100, 0.75);

}

.WalletThumb .ImgProfile img {
    border-radius: 0px;
    vertical-align: top;
}
