.UploadNFTIcon {
  color:rgba(100, 100, 100, 0.75);
  position: absolute;
  margin-left: 150px;
  margin-top: 10px;
}

.UploadNFTIcon:hover {
  color: black;  
  cursor: pointer;  
  }

.UploadSubmit .PostStatusInputSubmitButton{ 
  margin-top:50px;
  margin-left:250px;
}