.NotificationAllTips {
    margin-top: 20px;
}

.NotificationTipLine {
    display: inline;
}

.NotificationTipper {
    display: inline;
}

.TipperPost {
    background-color: white;
    border-radius:10px;
    padding:20px;
    border-radius: 10px;
    border: 1px solid #E6E6E6;
    margin-right:20px;
}

.NotificationTipLine .WalletCard {
    position:relative;
    height:70px;
    margin:5px;
    margin-bottom:5px;
    padding-bottom:5px;
    border-bottom: 0px;
}
