.ProfilePoster{
    position:relative;
    display:inline-block;
}

.ProfilePoster Input{   
    margin-left:10px;
    height:45px;
}

.ProfilePoster .ProfileNFT{
    position:relative;
    display:inline-block;
    top:10px;
}

.PreviewContract{
    margin: 5px;
    display: inline-block;
    border-radius: 10px;
    overflow: hidden;
}

.StatusProfPoster {
    position:relative;
    margin:10px;
    padding-bottom:10px;
}

.StatusProfPoster Button{
    margin-top:10px;
    border-radius:5px !important;
}

.PostStatusInputSubmitButton:hover {
    border: 1px solid black !important;
}