.CameraNFT {
  color:rgba(100, 100, 100, 0.75);
  position: absolute;
  margin-left: 50px;
  margin-top: 10px;
}

.CameraNFT:hover {
  color: black;  
  cursor: pointer;  
  }

.CameraTaker {
  width: 300px;
  height: 300px;
  overflow: hidden;
  border-radius: 10px;
}

.CameraTaker img {
  height: 100%;
} 

.ImageCam {
  padding-left:50px;
}

.CameraSubmit .PostStatusInputSubmitButton{ 
  margin-top:10px !important;
  margin-left:250px !important;
}

.CameraSwitch {
    position:absolute;
    left:20px;
    top:20px;
    color:white;
  }
  
.CameraSwitch:hover {
  color: #2081E2;  
  cursor: pointer;  
  }

.CameraList {
  position: absolute;
  left:20px;
  top:20px;
}

.CameraNumber {
  position: relative;
  color:white;
}

.CameraNumber:hover {
  color: #2081E2;  
  cursor: pointer;  
  }
