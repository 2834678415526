.NFTList {
  height:500px;
  overflow-y: scroll;
}

.PictureAligned{
  position:relative;
  overflow: hidden;
  border-radius: 10px;    
  display:inline-block;
}

.SingleNFTWallet {
  display:inline-block;
  position: relative;
  min-height: 100px;
  min-width: 100px;
}

.AllProfiles .PictureAligned{
  margin: 10px;
}