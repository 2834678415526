.Poster .PostStatusInputSubmit{ 
    margin-top:5px;
    position: relative;
    left:100%;
}

.Poster .PostStatusInputSubmitButton{ 
    position: relative;
    transform: translate(-100%);
}

textarea {
    border:0px;
    width:85%;
    margin-left: 50px;
}

.Poster{
    left: 0px;
    right:30px;
    padding-bottom: 0px;
}

.PosterTools {
    position: relative;
}

.PosterPicture {
    position: relative;
  }

.PosterPicture img {
    border-radius: 10px;
}

.modalQuotedPost {
    margin:10px 0px 10px 10px;
    padding:10px 0px 10px 10px;
    border-radius: 10px;
    border: 1px solid #E6E6E6;
}

.ReplyPicture {
    margin-right:30px;
    margin-left: 24px;
}

.ReplyPicture .postTreeBranch {
    bottom:-20px !important;
}

.tmp_image_holder {
    position:relative;
}

.post_tmp_image {
    position: relative;
    opacity: 0.5;
    border-radius:10px;
    display: inline-block;
    max-width: 300px;
    max-height: 300px;
    overflow: hidden;
}

.post_final_image {
    position: relative;
    border-radius:10px;
    display: inline-block;
    max-width: 300px;
    max-height: 300px;
    overflow: hidden;
}

.overlay_loading {
    top: 0px;
    left: 0px;
    padding-top: 20px;
    padding-left: 20px;
    position: absolute;
    z-index: 1000;
}

.overlay_minting {
    top: 0px;
    left: 0px;
    padding-top: 29px;
    padding-left: 12px;
    position: absolute;
    z-index: 1001;
    color : #1c7b87;
    font-size: 20px;
}

.OptionalSubPanel {
    position: relative;
  }

.OptionalSubPanelGallery {
    height: 350px;
    overflow-y: auto;
}
  
.OptionalSubPanel .PictureAligned:hover{
    cursor: pointer;  
}

.OptionalSubPanel .PictureAligned:hover img{
    opacity: 0.5;  
}