.WalletCard {
    position:relative;
    min-height: 110px;
}

.PictureWallet {
    position:absolute;
    left:0px;
    height:100px;
    width: 100px;
    display: inline-block;
}

.DescriptionWallet {    
    left:100px;
    position:absolute;
    padding-left:10px;
    right:0px;
    display: inline-block;
    word-break: break-all; 
}

.StatusWallet {
    position:relative;
    margin-left:100px;
    margin-top: 75px;
    margin-right:0px;
    padding-top:10px;
    padding-left:10px;
    padding-right:10px;
    padding-bottom:10px;
    display: inline-block;
}

.Selected {
    background: lightgray;
}

.ListSelectors {
    margin-bottom:50px;
    position:relative
}

.ListSelectorSupporters {
    position:absolute;
    text-align: center;
    left:100px;
    width:200px;
}

.ListSelectorSupporters:hover{
    background: #2081E2;  
    cursor: pointer;  
    }

.ListSelectorSupporting {
    position:absolute;
    text-align: center;
    left:300px;
    width:200px;
}

.ListSelectorSupporting:hover{
    background: #2081E2;  
    cursor: pointer;  
    }

.ListSelectorSupportersSelected {
    position:absolute;
    left:100px;
    background: lightgray;
    text-align: center;
    width:200px;
}

.ListSelectorSupportersSelected:hover{
    background: #2081E2;  
    cursor: pointer;  
    }

.ListSelectorSupportingSelected {
    position:absolute;
    left:300px;
    background: lightgray;
    text-align: center;
    width:200px;
}

.ListSelectorSupportingSelected:hover{
    background: #2081E2;  
    cursor: pointer;  
    }